import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import { GatsbyImage } from 'gatsby-plugin-image'
import ScrollEntrance from 'src/components/ScrollEntrance'
import PriceLarge from 'src/components/PriceLarge'
import ProductSlider from './components/ProductSlider'
import QuantityInput from 'src/components/QuantityInput'
import { OverlayContext } from 'src/state/OverlayState'
import { ShopifyContext } from 'src/state/ShopifyState'
import { colors, util } from 'src/styles'

import ProductOptions from 'src/components/ProductOptions'

const ProductSection = styled(Section)`
  ${ util.responsiveStyles('padding-bottom', 91, 51, 33, 26) }
`

const Actions = styled(ScrollEntrance)`
  ${ util.responsiveStyles('margin-top', 36, 32, 26, 22) }
  text-align: left;
`

const StyledOptions = styled(ProductOptions)`
  // ${ util.responsiveStyles('margin-top', 30, 26, 20, 16) }
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  .button {
    flex-grow: 1;
    max-width: 300px;
    .button-content {
      width: 100%;
    }
  }
`
const QuantityWrap = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`

const StyledQuantity = styled(QuantityInput)``

const StyledPriceLarge = styled(PriceLarge)`
  color: ${ colors.mainColor };
`

const ActionButton = styled(Button)`
  flex-shrink: 0;
  .button-content {
    width: 14em;
    padding-left: 0;
    padding-right: 0;
  }
`

const InfoWrapper = styled.div`
  h4 {
    text-align: center;
  }
`

const Description = styled.div`
  text-align: center;
  margin-top: 20px;
`

const PriceWrap = styled.div`
  width: 100%;
  text-align: center;
`

const mediaSizes = {
  noBleed: {
    normal: (100 / 26) * 11,
    large: (100 / 26) * 12,
    extraLarge: (100 / 26) * 13,
  },
  bleed: {
    normal: (100 / 26) * 13,
    large: (100 / 26) * 14,
    extraLarge: (100 / 26) * 15,
  },
}

const ProductImg = styled(GatsbyImage)`
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`

const MerchATF = ({
  children,
  className,
  product,
  variant,
  data,
  nextTheme,
  prevTheme,
  theme,
  scrolledToEnd,
  hasSections,
  barVisible,
  setVariant,
  colorSelection,
  productImages,
  swatchImages,
}) => {
  const mediaWidth = 'large'

  const imageSize = mediaSizes.bleed[mediaWidth]

  const {
    addVariantToCart,
    store: { adding },
  } = useContext(ShopifyContext)

  const [quantity, updateQuantity] = useState(1)

  const { toggleCart } = useContext(OverlayContext)

  const handleAddToCart = () => {
    addVariantToCart(variant.shopifyId, quantity)
    toggleCart()
  }

  return (
    <>
      <ProductSection
        className={className}
        setTheme="default"
        prevTheme={prevTheme}
        nextTheme={nextTheme}
        padded={false}
      >
        <Grid
          small="[1]"
          large="2 [11] 2 [8] 3"
          larger="3 [9] 3 [8] 3"
          rowGap={['7vw', '7vw', '80px']}
          vAlign="center"
          gridDirection={'ltr'}
        >
          <div>
            {productImages.length > 1 ? (
              <ProductSlider
                productImages={productImages}
                imageSize={imageSize}
              />
            ) : (
              productImages.map((image, index) => {
                // image.localFile.childImageSharp.fluid.sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'
                // image.localFile.childImageSharp.fluid.aspectRatio = 1
                return (
                  <ProductImg
                    key={'product-image-' + index}
                    image={image?.image?.gatsbyImageData}
                    alt={image.altText}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                )
              })
            )}
          </div>

          <Grid small="container" large="[1]">
            <InfoWrapper>
              <h4>{product.title}</h4>
              <PriceWrap>
                <StyledPriceLarge price={variant.price} size="small" />
              </PriceWrap>
              <Description
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </InfoWrapper>

            <ScrollEntrance delay={3}>
              <StyledOptions
                product={product}
                setPageVariant={setVariant}
                swatchImages={swatchImages}
                colorSelection={colorSelection}
              />
            </ScrollEntrance>

            <ScrollEntrance delay={4}>
              <QuantityWrap>
                <StyledQuantity
                  updateQuantity={updateQuantity}
                  quantity={quantity}
                  size="large"
                  buttonSize="small"
                  border={false}
                />
              </QuantityWrap>
            </ScrollEntrance>

            {variant.availableForSale && (
              <Actions delay={4}>
                <div>
                  <ActionButtons>
                    <ActionButton
                      size="large"
                      onClick={handleAddToCart}
                      loading={adding}
                    >
                      Add to Cart
                    </ActionButton>
                  </ActionButtons>
                </div>
              </Actions>
            )}
          </Grid>
        </Grid>
      </ProductSection>
    </>
  )
}

export default MerchATF
