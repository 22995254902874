import React, { useState, useContext, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { isEqual, find } from 'lodash'
import PropTypes from 'prop-types'
import { CustomSelect } from 'src/components/Select'
import { ShopifyContext } from 'src/state/ShopifyState'
import { typography, colors, animations, util } from 'src/styles'
import { GatsbyImage } from 'gatsby-plugin-image'
import Checkbox from 'src/components/Checkbox'

import SelectCircle from 'src/assets/images/ette-select-circle.svg'

const AllOptions = styled.div``

const OptionWrapper = styled.div`
  ${ ({ firstItem }) => !firstItem ? `
    ${ util.responsiveStyles('padding-top', 30, 26, 20, 16) }
  ` : '' }
`

const OptionLabel = styled.label`
  ${ typography.smallCaps }
  margin-bottom: .5em;
`

const ColorOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledSelectCircle = styled(SelectCircle)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: auto;
  transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;

`

const SizeOption = styled(Checkbox)`
  display: flex;
  align-items: center;
  justify-content: center;
  .checkbox {
    display: none;
  }
  .label-text {
    margin: 0;
    padding: 0;
    ${ typography.h6 }
    letter-spacing: 0;
  }
  border: 2px solid transparent;
  padding: 2px;
  padding-left: .6em;
  padding-right: .6em;
  ${ util.responsiveStyles('min-width', 48, 42, 42, 42) }
  ${ util.responsiveStyles('height', 48, 42, 42, 42) }
  ${ util.responsiveStyles('border-radius', 24, 21, 21, 21) }
  transition: border ${ animations.mediumSpeed } ease-in-out;
  ${ ({ lastItem }) => !lastItem ? `
    ${ util.responsiveStyles('margin-right', 12, 10, 10, 6) }
  ` : '' }
  ${ ({ checked, disabled }) => checked ? `
    // border-color: ${ colors.textColor };
    ${ StyledSelectCircle } {
      visibility: visible;
      opacity: 1;
    }
  ` : `
    ${ !disabled ? `
      &:hover {
        // border-color: ${ colors.mainColor };
        ${ StyledSelectCircle } {
          visibility: visible;
          opacity: 1;
        }
      }
    ` : `
      cursor: not-allowed;
    ` }
  ` }

  position: relative;
`

const ColorOption = styled(SizeOption)`
  .gatsby-image-wrapper {
    ${ util.responsiveStyles('width', 40, 34, 34, 34) }
    ${ util.responsiveStyles('height', 40, 34, 34, 34) }
    background: ${ colors.hrColor };
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  padding: 2px;
`

const SoldOutSection = styled.div`
  p {
    ${ typography.smallCaps }
    color: ${ colors.lightTextColor };
    margin: 22px 0 0;
  }
`

const ProductOptions = ({ product, setPageVariant, swatchImages, colorSelection, className }) => {
  const {
    options,
    variants,
    variants: [initialVariant]
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const {
    store: { client },
  } = useContext(ShopifyContext)

  const productVariant = client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleOptionChange = ({ target }, optionIndex) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
    setPageVariant({ ...selectedVariant })
  }

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })

    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const filteredOptions = options.filter(option => option.name !== 'Title' && option.name !== 'Arrangement')

  if (!filteredOptions || filteredOptions.length < 1) {
    return false
  }

  return (
    <AllOptions className={className}>
      {filteredOptions.map(({ id, name, values }, index) => {
        const optionsArray = []
        values.forEach(value => optionsArray.push({ label: value, value: value }))
        const thisOption = variant.selectedOptions.filter(option => option.name === name)[0]
        const currentValue = optionsArray.filter(option => option.value === thisOption.value)[0]

        if (name === 'color' || name === 'Color') {
          return (
            <OptionWrapper key={id} firstItem={filteredOptions.length === index + 1}>
              <ColorOptions>
                {optionsArray.map((option, optionIndex) => {
                  if (swatchImages && swatchImages.length > 0) {
                    return (
                      <ColorOption
                        key={optionIndex}
                        type='radio'
                        value={option.value}
                        id={option.value}
                        name={name}
                        onChange={() => handleOptionChange(event, index)}
                        checked={colorSelection === option.value}
                        lastItem={optionIndex + 1 === optionsArray.length}
                        disabled={checkDisabled(name, option.value)}
                      >
                        <GatsbyImage image={swatchImages.filter(image => image.altText.includes(option.label))[0].image.gatsbyImageData} />
                      </ColorOption>
                    )
                  } else {
                    return (
                      <ColorOption
                        value={option.value}
                        id={name}
                        disabled={checkDisabled(name, option.value)}
                      >
                        {option.label}
                      </ColorOption>
                    )
                  }
                })}
              </ColorOptions>
            </OptionWrapper>
          )
        } else if (name === 'size' || name === 'Size') {
          return (
            <OptionWrapper key={id} firstItem={filteredOptions.length === index + 1}>
              <ColorOptions>
                {optionsArray.map((option, optionIndex) => {
                  return (
                    <SizeOption
                      key={optionIndex}
                      type='radio'
                      value={option.value}
                      id={option.value}
                      name={name}
                      onChange={() => handleOptionChange(event, index)}
                      checked={colorSelection === option.value}
                      lastItem={optionIndex + 1 === optionsArray.length}
                      disabled={checkDisabled(name, option.value)}
                    >
                      {option.label}
                      <StyledSelectCircle />
                    </SizeOption>
                  )
                })}
              </ColorOptions>
            </OptionWrapper>
          )
        } else {
          return (
            <OptionWrapper key={id}>
              <OptionLabel htmlFor={name}>{name}</OptionLabel>
              <CustomSelect
                value={currentValue}
                id={name}
                name={name}
                options={optionsArray}
                onChange={event => handleOptionChange(event, index)}
                checkDisabled={checkDisabled}
              />
            </OptionWrapper>
          )
        }
      })}
      {!available && (
        <SoldOutSection>
          <p style={{ marginBottom: 0 }}>This Product is out of Stock!</p>
        </SoldOutSection>
      )}
    </AllOptions>
  )
}

ProductOptions.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductOptions
