import React, { Fragment, useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Cart from 'src/components/Cart'
// import Modal from 'src/components/Modal'
import MerchATF from 'src/components/MerchATF'

const propTypes = {
  data: PropTypes.object.isRequired
}

const MerchTemplate = ({ ...props }) => {
  const { data } = props
	// const { modal } = useContext(OverlayContext)
  const site = data.allContentfulSiteSettings && data.allContentfulSiteSettings.edges[0].node

  const product = data.shopifyProductVariant.product
  const variant = data.shopifyProductVariant
  const productVariants = product.variants

  const variantById = id => {
    return productVariants.filter(thisVariant => thisVariant.shopifyId === id)[0]
  }

  // const contentfulProduct = data.contentfulProduct
  // const productSettings = data.allContentfulProductSettings && data.allContentfulProductSettings.edges[0].node
  // const beforeSections = productSettings && productSettings.beforeSections
  // const afterSections = productSettings && productSettings.afterSections
  // const sections = contentfulProduct && contentfulProduct.sections

  const [barVisible, setBar] = useState(false)
  const [currentVariant, setVariant] = useState(variant)

  useEffect(() => {
    navigate('/shop/' + product.handle + '?variant=' + currentVariant.shopifyId)
  }, [currentVariant])

  useEffect(() => {
    if (location.search.includes('?variant=')) {
      navigate('/shop/' + product.handle + location.search)
      const variantIdFromUrl = String(location.search).split('?variant=')[1]
      setVariant(variantById(variantIdFromUrl))
    } else {
      navigate('/shop/' + product.handle + '?variant=' + currentVariant.shopifyId)
    }
  }, [])

  let swatchImages = false
  if (product.images && product.images[0]) {
    // weed out color swatch images
    swatchImages = product.images.filter(image => image.altText && image.altText.includes('swatch'))
    // shareImage = product.images[0].localFile.childImageSharp.fluid.src
  }

  let currentColorSelection = false
  if (currentVariant.selectedOptions.filter(option => option.name === 'Size').length > 0) {
    currentColorSelection = currentVariant.selectedOptions.filter(option => option.name === 'Size')[0].value
    // productImages = productImages.filter(image => image.altText && image.altText.includes(currentColorSelection))
  }

  return (
    <Fragment >
      <SEO
        title={product.title}
        description={product.description}
        siteSettings={site}
				// keywords={seo.keywords}
				// shareImage={shareImage && process.env.GATSBY_HOST + shareImage}
      />
      <Header
        // hasAtf={true}
        bannerText={site.bannerText}
        bannerColor={site.bannerColor}
        navigation={site.navigation}
        rightNavigation={site.rightNavigation}
        location={product.handle}
        cartLink={site.cartLink}
      />
    {/*
    */}
      <Cart/>
      <MerchATF
        product={product}
        swatchImages={swatchImages}
        productImages={product.images}
        variant={currentVariant}
        hasSections={false}
        barVisible={barVisible}
        setVariant={setVariant}
        colorSelection={currentColorSelection}
      />

      <Footer {...site} />
    </Fragment>
  )
}

MerchTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    shopifyProductVariant(shopifyId: { eq: $id }) {
      ...Variant
    }
	}
`

export default MerchTemplate
