import React from 'react'
import styled from '@emotion/styled'
import { typography, util, colors } from 'src/styles'

const PriceWrapper = styled.div`
	display: inline-flex;
	align-items: flex-start;
	${ ({ size }) => size === 'small' ? `
		${ util.responsiveStyles('font-size', 32, 24, 24, 22) }
	` : `
		${ util.responsiveStyles('font-size', 46, 32, 32, 30) }
	` }
	font-family: ${ typography.primaryFont };
`

const Dollars = styled.h6`
	color: ${ colors.pink5 };
`

const PriceLarge = ({ className, price, size = 'normal' }) => {
	if (!price) {
		return false
	}
	return (
		<PriceWrapper className={className} size={size}>
			<Dollars>${price}</Dollars>
		</PriceWrapper>
	)
}

export default PriceLarge
